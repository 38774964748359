import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import jwt_decode from 'jwt-decode';
// Components
import LoadingOverlay from 'headflorist/components/common/LoadingOverlay';
import CustomButton from 'headflorist/components/common/CustomButton';
import AssignOrderModal from './AssignOrderModal';
import NotDoneModal from './NotDoneModal';
import QualityCheckConfirmModal from 'headflorist/components/common/QualityCheckConfirmModal';
// Helpers
import { hasPermission, status } from 'constants/Helpers';
// API
import OrdersListService from 'headflorist/services/OrdersListService';
// Selectors
import { getPermissionsData } from 'headflorist/store/app/AppSelectors';
import { getFirebasePermissions } from 'store/app/AppSelectors';
// Localstorage
import { getFromLocalStorage } from 'headflorist/helpers/LocalStorage';

const ChangeStatusBtn = ({
  item,
  initialParams,
  fetchMoreData,
  details,
  fetchFlorists,
  showAllCTA,
  hasSubmittedBefore,
  hasDeviation,
}) => {
  const [isLoaderOpen, setIsloaderOpen] = useState(false);
  const [isModalOpen, setIsOpenModal] = useState(false);
  const [isQualityCheckModalOpen, setIsQualityCheckModalOpen] = useState(false);
  const [isNotDoneModalOpen, setIsNotDoneModalOpen] = useState(false);
  const [isProductionOfficer, setIsProductionOfficer] = useState(false);
  const permissions = useSelector((state) => getPermissionsData({ state }));
  const firebasePermissions = useSelector((state) => getFirebasePermissions({ state }));
  const opsID = getFromLocalStorage('opsId');
  const deviationEnabled = firebasePermissions?.enable_deviation?.filter(
    (ops) => ops.id === parseInt(opsID)
  )?.[0]?.enable;
  const isQualityCheckEnabled = firebasePermissions?.enable_quality_check?.find(
    (ops) => ops.id === parseInt(opsID)
  )?.enable;
  const location = useLocation();
  const currentPage = location.pathname.slice(1);
  const navigate = useNavigate();

  const fetchData = () => {
    if (details) {
      fetchMoreData(item?.id);
    } else {
      fetchMoreData(initialParams, 1);
      if (fetchFlorists) {
        fetchFlorists();
      }
    }
  };

  const handleChangeStatus = async (item) => {
    setIsloaderOpen(true);
    try {
      if (item?.preparationStatus === 3 || item?.preparationStatus === 7) {
        await OrdersListService.changeOrderPreparationStatus({ Status: 2 }, item?.id);
        setTimeout(() => fetchData(), 1000);
      } else if (item?.preparationStatus === 2) {
        if (isQualityCheckEnabled && item?.preparationType === 1) {
          setIsQualityCheckModalOpen(true);
        } else {
          await OrdersListService.changeOrderPreparationStatus({ Status: 0 }, item?.id);
          setTimeout(() => fetchData(), 500);
        }
      } else if (
        !item?.preparationStatus ||
        item?.preparationStatus === 6 ||
        item?.preparationStatus === 1
      ) {
        if (isProductionOfficer) {
          setIsOpenModal(true);
        } else {
          await OrdersListService.assignFloristStatus(
            {
              assigneeId:
                getFromLocalStorage('floristId') ?? jwt_decode(getFromLocalStorage('token'))?.id,
            },
            item?.id
          );
          setTimeout(() => fetchData(), 1000);
        }
      }
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'Status change failed!');
      setIsloaderOpen(false);
    }
    setIsloaderOpen(false);
  };

  const isSubmitted = (productId, cartId) => {
    return hasSubmittedBefore?.filter(
      (sb) => sb?.productId === productId && sb?.cartId === cartId
    )?.[0]?.hasSubmittedBefore;
  };

  const isDoneDisable = (s) => {
    if (s === 'Done' && deviationEnabled && currentPage === 'orders') {
      return true;
    } else {
      if (hasSubmittedBefore !== null && hasDeviation !== null) {
        let disableBtn = false;
        hasDeviation?.forEach((d) => {
          if (d?.hasDeviation && !isSubmitted(d?.productId, d?.cartId)) {
            disableBtn = true;
          }
        });
        return s === 'Done' && disableBtn;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    setIsProductionOfficer(hasPermission(permissions, 'florist_orders', 'head_assign'));
  }, [permissions]);

  return (
    <>
      {isLoaderOpen && <LoadingOverlay />}
      {isQualityCheckModalOpen && (
        <QualityCheckConfirmModal
          handleCancelClick={() => setIsQualityCheckModalOpen(false)}
          handleProceedClick={() => navigate(`/qualitycheck/${item?.id}`)}
          message={'Please proceed to complete Quality Check!'}
          isOpen={isQualityCheckModalOpen}
          setIsOpen={setIsQualityCheckModalOpen}
          cancelBtnName="Cancel"
        />
      )}
      {isModalOpen && (
        <AssignOrderModal
          isModalOpen={isModalOpen}
          setIsOpenModal={setIsOpenModal}
          orderData={item}
          fetchData={fetchData}
        />
      )}
      {isNotDoneModalOpen && (
        <NotDoneModal
          isModalOpen={isNotDoneModalOpen}
          setIsOpenModal={setIsNotDoneModalOpen}
          orderData={item}
          fetchData={fetchData}
        />
      )}
      {showAllCTA ? (
        typeof status[item?.preparationStatus ?? 6] === 'string' ? (
          <CustomButton
            handleClick={() => handleChangeStatus(item)}
            type={`status ${item?.preparationStatus === 0 ? 'success hidden' : 'primary'}`}
            disabled={item?.preparationStatus === 4}
            name={status[item?.preparationStatus ?? 6]}
          />
        ) : Array.isArray(status[item?.preparationStatus ?? 6]) ? (
          status[item?.preparationStatus ?? 6]?.map((s) => {
            return (
              <CustomButton
                handleClick={
                  s === 'Done' ? () => handleChangeStatus(item) : () => setIsNotDoneModalOpen(true)
                }
                type={`status ${s === 'Done' ? 'success' : 'danger'} ${
                  item?.preparationStatus === 0 ? 'hidden' : ''
                }`}
                name={s}
                key={s}
                disabled={
                  isDoneDisable(s) ||
                  (s === 'Done' &&
                    hasDeviation?.some((el) => el.hasDeviation) &&
                    !item?.reviewStatus)
                }
              />
            );
          })
        ) : (
          <CustomButton
            handleClick={() => handleChangeStatus(item)}
            type={`status secondary ${item?.preparationStatus === 0 ? 'hidden' : ''}`}
            disabled={!status[item?.preparationStatus ?? 6].enabled}
            name={status[item?.preparationStatus ?? 6].text}
          />
        )
      ) : (
        <CustomButton
          handleClick={() => handleChangeStatus(item)}
          type="status primary"
          disabled={[0, 2, 3, 4, 5, 7, 8].includes(item?.preparationStatus)}
          name={status[1]}
        />
      )}
    </>
  );
};

export default ChangeStatusBtn;
