import { FormatString } from './FormatString';

export const setEndPoint = (endPoint, ...extraParameters) => {
  if (extraParameters.length > 0) {
    const newEndPoint = FormatString(endPoint, ...extraParameters);
    return newEndPoint;
  } else {
    return endPoint;
  }
};
