import React, { useEffect, useState } from 'react';
import CustomButton from 'headflorist/components/common/CustomButton';
import InventoryTable from './InventoryTable';
import ImagesList from './ImagesList';
// API
import OrderDetailsService from 'headflorist/services/OrderDetailsService';

const ProductDetails = ({
  cartItem,
  handleCartDetails,
  orderMapId,
  preparationStatus,
  hasSubmittedBefore,
  hasDeviation,
  setHasSubmittedBefore,
  setHasDeviation,
  orderId,
}) => {
  const [inventoryDetails, setInventoryDetails] = useState([]);
  const fetchCartDetails = async () => {
    try {
      const res = await OrderDetailsService.getInventoryDetails(
        orderMapId,
        cartItem?.productId,
        cartItem?.id,
        orderId
      );
      setInventoryDetails(res?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchCartDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="productDetails-left-col">
        <div className="productDetails-left-header"></div>
        <ImagesList listOfImages={inventoryDetails?.productImages} />
        <div className="productDetails-left-details">
          <p>
            <b>Qty: {cartItem?.qty}</b>
          </p>
          <p>
            {cartItem?.proName} {cartItem?.productSku ? `(${cartItem?.productSku})` : ''}
          </p>
          <p>
            <b>Personalized msg:</b> {cartItem?.cakeMessage}
          </p>
        </div>
        <CustomButton
          id="show-basket"
          name="Show Basket Details"
          handleClick={() => handleCartDetails(cartItem?.id)}
          disabled={false}
          type="basket"
        />
      </div>
      <div className="productDetails-right-col">
        <div className="productDetails-right-header">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Item Image</th>
                <th scope="col">Name</th>
                <th scope="col">Barcode</th>
                <th scope="col">Qty</th>
                <th scope="col">Used Qty</th>
                <th scope="col">Damaged Qty</th>
                <th scope="col">Damaged Image</th>
              </tr>
            </thead>
          </table>
        </div>
        <InventoryTable
          inventoryData={inventoryDetails?.inventoryDto}
          orderMapId={orderMapId}
          preparationStatus={preparationStatus}
          hasSubmittedBefore={hasSubmittedBefore}
          hasDeviation={hasDeviation}
          setHasSubmittedBefore={setHasSubmittedBefore}
          setHasDeviation={setHasDeviation}
          cartQty={cartItem?.qty}
          fetchCartDetails={fetchCartDetails}
          cartId={cartItem?.id}
        />
      </div>
    </>
  );
};

export default ProductDetails;
