import React, { useState } from 'react';
import ProductDetails from './ProductDetails';
import FavoriteFlowers from './FavoriteFlowers';
import BasketModal from './BasketModal';

const CartDetailsCard = ({
  cartItem,
  orderMapId,
  preparationStatus,
  hasSubmittedBefore,
  hasDeviation,
  setHasSubmittedBefore,
  setHasDeviation,
  orderId,
}) => {
  const [isModalOpen, setIsOpenModal] = useState(false),
    [basketId, setBasketId] = useState('');

  const handleCartDetails = (id) => {
    setBasketId(id);
    setIsOpenModal((prev) => !prev);
  };
  return (
    <>
      {isModalOpen && (
        <BasketModal
          isModalOpen={isModalOpen}
          setIsOpenModal={setIsOpenModal}
          basketId={basketId}
        />
      )}
      <div className="orderDetails-cartItem-wrapper" key={cartItem?.productId}>
        <div className="card cartDetails-card">
          <div className="card-body">
            <ProductDetails
              cartItem={cartItem}
              handleCartDetails={handleCartDetails}
              orderMapId={orderMapId}
              preparationStatus={preparationStatus}
              hasSubmittedBefore={hasSubmittedBefore}
              hasDeviation={hasDeviation}
              setHasSubmittedBefore={setHasSubmittedBefore}
              setHasDeviation={setHasDeviation}
              orderId={orderId}
            />
          </div>
          <div className="card-footer">
            <FavoriteFlowers cartId={cartItem?.id} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CartDetailsCard;
