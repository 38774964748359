export const endPoints = {
  // permission
  getUserPermission: `user/operation/florist/florist-actions`,
  // orders
  getTimeSlotByDate: `order/Florists/orders/slots`,
  ordersListData: `order/florists/orders/{0}`,
  getFlorsitsList: `order/florists/all`,
  orderDetails: `order/florists/order/{0}/details`,
  cartDetails: `order/florists/cart/{0}/details`,
  inventoryDetails: `order/florists/order/{0}/inventory/details`,
  basketDetails: `order/florists/order/{0}/basket/details`,
  getFavFlowersData: `catalog/hub/SubscriptionFavorites/{0}`,
  login: `user/operation/UserDetails/outsource/login`,
  session: `user/operation/authentication/sso/{0}/session`,
  country: `/home/operation/countries`,
  completedOrders: `order/florists/orders`,
  assignFlorist: `order/florists/order/{0}/{1}`,
  // group by
  groupedBySku: `order/Florists/orders/bysku`,
  groupedByZone: `order/Florists/orders/bydeliveryzone`,
  // notifications
  getNotificationsList: `order/florists/notifications`,
  getReadNotification: `order/florists/notifications/{0}`,
  // Production
  getUnassignedBatches: `/prodapp/florist/unassigned-batches`,
};
